//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { browserLanguageDetect } from '~/plugins/utilities'

export default {
  name: 'DefaultLayout',
  components: {
    CartFreeProductDiscountPopup: () =>
      import('~/components/checkout/CartFreeProductDiscountPopup.vue'),
    Salebar: () => import('@/containers/Salebar'),
    Header: () => import('@/containers/header/Header'),
    Sider: () => import('@/components/Sider'),
    Wishlist: () => import('@/containers/Wishlist'),
    ShoppingCart: () => import('@/containers/ShoppingCart'),
    Login: () => import('@/containers/Login'),
    Account: () => import('@/containers/Account'),
    Notifications: () => import('@/containers/Notifications'),
    AddToBagFeedback: () => import('@/containers/AddToBagFeedback'),
    Cookies: () => import('@/containers/Cookies'),
    Footer: () => import('@/containers/footer/Footer'),
    CurrencySelectorModal: () =>
      import('@/containers/CurrencySelectorModal/index'),
  },
  data() {
    return {
      detectLanguageModalShow: false,
      clearHideTimer: true,
      hideSidebar: true,
      shadow: false,
      saleBarShowedPages: [
        'ladies',
        'mens',
        'childrens',
        'sales',
        'collections',
        'products',
        'produkte',
        'damen',
        'herren',
        'kinder',
        'ausverkauf',
        'sammlungen',
        'femme',
        'homme',
        'enfants',
        'promotions',
        'produits',
        'productos',
        'promociones',
        'hombre',
        'mujer',
        'ninos',
        'colleciones',
      ],
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    // Do not include the query in the hreflang tags.
    const objQuery = this.$route.query
    let routeQuery = '?'
    Object.keys(objQuery).forEach(function (key) {
      routeQuery += key + '=' + objQuery[key]
    })
    const alternates = []
    i18nHead.link.forEach((locale) => {
      // Do not include the query in the hreflang tags.
      locale.href = locale.href.replace(routeQuery, '')
      if (locale.hreflang === 'x-default' || locale.rel === 'canonical') {
        alternates.push(locale)
      }
      this.$i18n.locales.forEach((element) => {
        if (locale.hreflang === element.hreflang) {
          alternates.push(locale)
        }
      })
    })
    i18nHead.link = alternates
    return {
      i18nHead,
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class:
          (this.shadow || this.isSidebarActive ? 'overflow-hidden' : '') +
          (this.$device.isWindows ? ' custom-scroll' : ''),
      },
      meta: [
        {
          name: 'google-site-verification',
          content: process.env.GOOGLE_SITE_VERIFICATION_CODE,
        },
        ...i18nHead.meta,
      ],
      link: [...i18nHead.link],
      script: [
        {
          src: '/js/batBing.js',
        },
      ],
    }
  },
  computed: {
    showSaleBar() {
      let showSaleBar = false
      this.$route.path.split('/').forEach((link) => {
        if (this.saleBarShowedPages.includes(link)) showSaleBar = true
      })
      // hide sale bar in homepage
      showSaleBar =
        this.$route.path === '/' || /clearance/g.test(this.$route.path)
          ? false
          : showSaleBar

      return showSaleBar
      // this.pages.includes(this.$route.path.split('/')[1]) ||
      // this.pages.includes(this.$route.path.split('/')[2])
    },
    pageLink() {
      if (this.$route.path !== '/') {
        return process.env.CLIENT_APP_URL + this.$route.path + '/'
      } else {
        return process.env.CLIENT_APP_URL + '/'
      }
    },
    ...mapGetters('ui', [
      'isSidebarActive',
      'isWishlistActive',
      'isCartActive',
      'isAccountActive',
      'footer',
      'darkMode',
      'isAddToBagFeedbackActive',
      'expressLoaderModal',
    ]),
    ...mapGetters('cookies', ['isCookiesBarActive']),
    ...mapGetters('notification', ['list']),
    ...mapGetters('cart', ['freeProductDiscount']),
    shouldBackdropBeVisible() {
      return (
        this.isSidebarActive ||
        this.isAccountActive ||
        this.isCartActive ||
        this.isWishlistActive ||
        this.isAddToBagFeedbackActive ||
        this.shadow
      )
    },
    isFooterActive() {
      return this.footer === 'default'
    },
    isDarkMode() {
      return (
        this.darkMode &&
        !/cart/g.test(this.$route.path) &&
        !/auth/g.test(this.$route.path) &&
        !/checkout/g.test(this.$route.path) &&
        !/account/g.test(this.$route.path) &&
        this.$i18n.locale === 'en'
      )
    },
    locale() {
      return this.$i18n.locale
    },
    currency() {
      return this.$store.state.settings.currency
    },
    shownInUK() {
      return this.$i18n.locale === 'en'
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.closeAll()
        this.$refs.appBody.scrollTop = 0

        // O.K.: Keep this timeout at all cost!
        setTimeout(() => {
          if (this.$refs.appBody) {
            this.$refs.appBody.scrollTop = 0
          }
        }, 300)
      })
    },
    // locale() {
    //   this.$nextTick(() => {
    //     this.$store.dispatch('wishlist/browserInit')
    //     this.$store.dispatch('cart/browserInit')
    //     this.$store.dispatch('recentlyViewed/browserInit')
    //     this.$store.dispatch('cookies/browserInit')
    //     this.$store.dispatch('search/browserInit')
    //     this.$store.dispatch('checkout/browserInit')
    //     this.$nuxt.refresh()
    //   })
    // },
    // currency() {
    //   this.$nextTick(() => {
    //     this.$store.dispatch('wishlist/browserInit')
    //     this.$store.dispatch('cart/browserInit')
    //     this.$store.dispatch('recentlyViewed/browserInit')
    //     this.$store.dispatch('cookies/browserInit')
    //     this.$store.dispatch('search/browserInit')
    //     this.$store.dispatch('checkout/browserInit')
    //     this.$nuxt.refresh()
    //   })
    // },
    expressLoaderModal(val) {
      if (val) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
    '$store.state.settings.currency'() {
      this.updateSettingsData()
    },
    '$store.state.settings.country'() {
      this.updateSettingsData()
    },
    '$store.state.settings.shippingCountry'() {
      this.updateSettingsData()
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.watchScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.watchScroll)
  },
  mounted() {
    this.$store.dispatch('wishlist/browserInit')
    this.$store.dispatch('cart/browserInit')
    this.$store.dispatch('recentlyViewed/browserInit')
    this.$store.dispatch('cookies/browserInit')
    this.$store.dispatch('search/browserInit')
    this.$store.dispatch('checkout/browserInit')
    this.watchResize()
    window.addEventListener('resize', this.watchResize)
    window.addEventListener('storage', this.watchCountryChange)
    this.$root.$on('shadow', this.toggleShadow)

    this.$auth.$storage.watchState('loggedIn', (loggedIn) => {
      this.updateSentryUser()

      this.$store.dispatch('cart/browserInit')
      if (loggedIn) {
        this.$store.dispatch('wishlist/merge')
      } else {
        this.$store.dispatch('wishlist/reset')
      }
    })

    this.$store.watch(
      (state) => state.session.sessionId,
      () => {
        this.updateSentryUser()
      }
    )

    this.updateSentryUser()
    this.updateSettingsData()

    // Country detection
    const locale = browserLanguageDetect(this.$i18n)
    if (!this.$cookies.get('detectLanguageModal')) {
      if (locale !== 'en') {
        this.detectLanguageModalShow = true
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.watchResize, false)
    this.$root.$off('shadow', this.toggleShadow)
  },
  methods: {
    ...mapActions('ui', ['toggleSider', 'setAddToBagFeedback']),
    watchCountryChange(event) {
      if (
        !['country', 'currency'].includes(event.key) ||
        event.oldValue === event.newValue
      ) {
        return
      }

      const newURL = this.getLinksByHreflang(event.newValue)[0]

      switch (event.key) {
        case 'country':
          if (newURL.includes('?q=')) location.replace(newURL)
          else location.replace(newURL + location.search)
          break

        case 'currency':
          location.reload()
          break
      }
    },

    getLinksByHreflang(hreflang) {
      const replaceList = [
        { source: 'en', target: 'en-GB' },
        { source: 'ie', target: 'en-IE' },
        { source: 'us', target: 'en-US' },
        { source: 'de', target: 'de-DE' },
        { source: 'fr', target: 'fr-FR' },
        { source: 'es', target: 'es-ES' },
      ]

      const condition = replaceList.find((element) => {
        return element.source === hreflang
      })

      if (condition) {
        hreflang = condition.target
      }

      const normalizedLang = hreflang.toLowerCase()

      const links = Array.from(
        document.querySelectorAll('link[rel="alternate"]')
      )
      return links
        .filter(
          (link) =>
            link.getAttribute('hreflang').toLowerCase() === normalizedLang
        )
        .map((link) => link.getAttribute('href'))
    },
    onClearHideTimer() {
      this.clearHideTimer = !this.clearHideTimer
    },
    onHideSidebar() {
      this.hideSidebar = !this.hideSidebar
    },
    closeAll() {
      this.$store.dispatch('ui/closeAll')
    },
    watchScroll(e) {
      this.$root.$emit('bodyscroll', e)
    },
    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      })
    },
    watchResize() {
      if (window !== undefined) {
        const width = window.innerWidth
        this.$store.dispatch('ui/updateSize', width)
        this.$root.$emit('resize', width)
      }
    },
    toggleShadow(value) {
      this.shadow = value
    },
    updateSentryUser() {
      try {
        let userData = {
          session_id: this.$store.state.session.sessionId,
        }

        if (this.$auth.loggedIn) {
          userData = {
            ...userData,
            id: this.$auth.user.id,
            email: this.$auth.user.email,
          }
        }

        this.$sentry.setUser(userData)
      } catch (e) {
        console.error(e)
      }
    },
    updateSettingsData() {
      try {
        this.$sentry.setExtra('currency', this.$store.state.settings.currency)
      } catch (e) {
        console.error(e)
      }

      try {
        this.$sentry.setExtra('country', this.$store.state.settings.country)
      } catch (e) {
        console.error(e)
      }

      try {
        this.$sentry.setExtra(
          'shippingCountry',
          this.$store.state.settings.shippingCountry
        )
      } catch (e) {
        console.error(e)
      }
    },
  },
}

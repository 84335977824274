//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    floated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['activeSider', 'darkMode']),
  },
  methods: {
    handleMouseLeave() {
      this.$store.dispatch('ui/hideSiderInAWhile', {
        name: this.activeSider,
        time: 600, // same as transition duration
      })
    },
    handleMouseOver() {
      // Clear hide timeout for cart, wishlist etc
      // we are using mouseover because there is a posibility for sider to be
      // under user cursor when user opens it
      // mouseover.once also doesn't work because the timer is not settled at the time
      this.$store.dispatch('ui/clearHideSiderTimeout')
    },
  },
}
